import React, { useState } from 'react';
import Navigation from '../components/Navigation';
import '../styles/main.scss';
import '../styles/navigation.scss';
import Modal from '../sections/Modal';
import CookieConsent from "react-cookie-consent";
export const WindowSizeContext = React.createContext();

const MainLayout = (props) => {
  const [modalOpen, setModalOpen] = useState(false);

  return <><div>{props.children}</div>
  <CookieConsent 
  location="bottom"
  buttonText="Rozumiem"
  cookieName="myAwesomeCookieName2"
  buttonClasses="confirmCookies cookieButton"
  contentClasses="informationContainer"
  containerClasses="cookieContainer"
  disableStyles
>
  <span className="cookieText"> Strona fizjoergo.pl wykorzystuje cookies, by działać jak najsprawniej. Kontynuując przeglądanie wyrażasz zgodę na korzystanie przez nas z cookies.</span>
  <button className="cookieButton">
      <a className="cookieLink" href="/polityka_prywatnosci.pdf">Polityka Prywatności</a>

  </button>
</CookieConsent>
</>
};

export default MainLayout;
