import React from 'react';
import '../styles/footer.scss';
import logo from '../images/logon.svg';
import instagram from '../images/instagram.svg';
import facebook from '../images/facebook.svg';
import pin from '../images/pin.svg';
import phone from '../images/phone.svg';

const Footer = () => {
  return (
    <footer className='footer'>
      <div className='container'>
        <div className='logo'>
          <img width height src={logo} alt='logo' />
        </div>
        <div className='social'>
          <a
            href='https://www.instagram.com/fizjoergo/'
            target='_blank'
            className='single-social'
          >
            <img width height src={instagram} alt='' />
            <span>@fizjoergo</span>
          </a>
          <a
            href='https://www.facebook.com/FizjoErgo'
            target='_blank'
            className='single-social'
          >
            <img src={facebook} alt='' />
            <span>@FizjoErgo</span>
          </a>
        </div>
        <div className='name'>
          Gabinet fizjoterapii, terapii manualnej i masażu
        </div>
        <address id='kontakt' className='address'>
          <img width height src={pin} alt='pin' />
          <div className='address-container'>
            <span>ul. Polna 32A</span>
            <span>87-100 Toruń</span>
          </div>
        </address>
        <div className='phones'>
          <div className='single-phone'>
            <img width height src={phone} alt='telefon' />
            <a href='tel:+48518359935'>518359935</a>
          </div>
        </div>
        <div className='phones'>
          <div className='single-phone'>
          <a className="cookieLink" href="/polityka_prywatnosci.pdf">Polityka Prywatności</a>
          </div>
        </div>
      </div>
    </footer>
  );
};
export default Footer;
